/* ==========================================================================
   SAO DESIGN SYSTEM STYLES - CORE
   ========================================================================== */

/**
 * Based off inuitcss, by @csswizardry (github.com/inuitcss)
 * extended for Washington State Auditors Office by Fellow (thinkfellow.com)
 */

/**
 * CONTENTS
 *
 * SETTINGS
 * Web fonts............Import Google Web fonts
 * Tokens...............Variables generated from src/tokens/tokens.js
 *
 * TOOLS
 * Font size............A mixin which guarantees baseline-friendly line-heights.
 * Clearfix.............Micro clearfix mixin.
 * Hidden...............Mixin for hiding elements.
 * Sass MQ..............Media query manager.
 *
 * GENERIC
 * Box-sizing...........Better default `box-sizing`.
 * Normalize.css........A level playing field using @necolas’ Normalize.css.
 * Reset................A tiny reset to complement Normalize.css.
 * Shared...............Sensibly and tersely share some global commonalities
 *                      (particularly useful when managing vertical rhythm).
 *
 * ELEMENTS
 * Page.................Set up our document’s default `font-size` and
 *                      `line-height`.
 * Typography...........Styling for headings, paragraphs, links, and inline text elements.
 *
 * OBJECTS
 * Form.................Forms, input groups, and labels.
 *
 * COMPONENTS
 * All components.......Individual component styles from the ../Components directory.
 */





// SETTINGS
@import url("https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700|Roboto:400,500,700");
@import url("https://use.fontawesome.com/releases/v5.7.2/css/solid.css");
@import url("https://use.fontawesome.com/releases/v5.7.2/css/fontawesome.css");
@import "settings/settings.tokens";





// TOOLS
@import "tools/tools.font-size";
@import "tools/tools.clearfix";
@import "tools/tools.hidden";
@import "../../../node_modules/sass-mq/mq";





// GENERIC
@import "generic/generic.box-sizing";
@import "generic/generic.normalize";
@import "generic/generic.reset";
@import "generic/generic.shared";





// ELEMENTS
@import "elements/elements.page";
@import "elements/elements.typography";





// OBJECTS
@import "objects/objects.form";





// COMPONENTS
@import "../../Components/button/button";
@import "../../Components/checkbox/checkbox";
@import "../../Components/dropdown/dropdown";
@import "../../Components/input/input";
@import "../../Components/radio-button/radio-button";
@import "../../Components/slider/slider";
@import "../../Components/tab-bar/tab-bar";
@import "../../Components/typography/typography";
