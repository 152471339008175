.filters {
	display: flex;
	margin-bottom: $spacing-base;

	&__filter {
		width: 100%;
		margin-right: $spacing-small;

		&:last-child {
			margin-right: 0;
		}

		&--with-hint {
			display: flex;

			& > *:first-child {
				width: 100%;
			}
		}
	}
}
