.filer-report {
	&__sub-title {
		text-align: center;
	}

	&__notes {
		font-size: 0.85em;
		list-style-type: none;
	}

	&__data-grid {
		.dx-header-filter {
			color: $bonanza-blue-medium;
			@include single-transition;

			&:hover {
				color: $bonanza-blue-dark;
			}
		}

		.dx-header-filter-empty {
			color: $bonanza-blue-light;
		}

		.dx-datagrid-export-button {
			@include export-button();

			&::after {
				display: none !important;
			}
		}

		.dx-datagrid-content .dx-datagrid-table .dx-row > td:first-child,
		.dx-datagrid-content .dx-datagrid-table .dx-row > tr > td:first-child {
			border-left: 1px solid $color-neutral-lighter;
		}

		.dx-datagrid .dx-column-lines > td {
			border-left: 1px solid $color-neutral-lighter;
			border-right: 1px solid $color-neutral-lighter;
			border-bottom: 1px solid $color-neutral-lighter;
		}

		.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
			border-bottom: 0;
		}

		.dx-freespace-row {
			height: 0px !important;
			visibility: hidden !important;
		}

		.dx-datagrid-table {
			color: $color-neutral-darkest;
		}

		.dx-datagrid-content {
			color: $color-neutral-base;
		}

		.dx-pager {
			.dx-pages {
				.dx-page {
					@include border-radius(0);
					background-color: $bonanza-green-medium;
					color: white;
					@include single-transition;
					border: solid 2px transparent;

					&:hover {
						background-color: $bonanza-green-dark;
					}
				}

				.dx-selection, .dx-page-sizes .dx-selection {
					border: solid 2px $bonanza-green-dark;
				}
			}
		}
	}
}
