.dx-tabpanel {

    .dx-tabs {
        display: none !important;
    }

    .dx-multiview-wrapper {
        border: 0;
    }
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    display: none !important;
}

.mapboxgl-ctrl-logo::after {
    display: none !important;
}
