.c-dropdown {
    position: relative;
    font-size: $font-size-small;

    &__select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: $spacing-small $spacing-large $spacing-small $spacing-small;
        background-color: $color-neutral-lightest;
        // background-image: url('/assets/images/dropdown-arrow.svg');
        // background-repeat: no-repeat;
        // background-position: right;
        border: $border-width-base solid $color-neutral-lightest;
        border-radius: $border-radius-base;
        width: 100%;

        &:focus {
            border-color: $color-neutral-light;
            outline: none;
        }

        // Fix for IE
        &::-ms-expand {
            display: none;
        }
    }

    &::after {
        content: '\f0d7';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        background: $color-secondary-base;
        color: $color-neutral-white;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: $spacing-large;
        text-align: center;
        vertical-align: middle;
        line-height: 44px;
        border-top-right-radius: $border-radius-base;
        border-bottom-right-radius: $border-radius-base;
        border-left: 0;
    }

    &--inverted {

        .c-dropdown__select {
            color: $color-neutral-white;
            background-color: $color-neutral-base;
            border-color: $color-neutral-lighter;
        }

        &::after {
            border: $border-width-base solid $color-neutral-lighter;
            border-left: 0;
        }
    }
}

// Fix for IE 9
@media screen and (min-width:0\0) {
    .c-dropdown {
        background: none\9;
        padding: 5px\9;
    }
}
