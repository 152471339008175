@import 'settings/settings.tokens';

/**
Input components

* dx-texteditor
* dx-dropdowneditor
* dx-switch
* dx-radiobutton
* dx-slider
* dx-selectbox

*/


/** dx-texteditor */

.dx-texteditor.dx-editor-outlined {
	// border: 0;
	background: transparent;
}

.dx-texteditor-input,
.dx-texteditor.dx-editor-outlined {
	background: $color-neutral-lightest;
	border-radius: $border-radius-base;
	@include single-transition;

	&:hover,
	&:focus {
		background: $color-neutral-lighter;
	}
}

.dx-texteditor.dx-editor-outlined {
	.dx-texteditor-input {
		background: transparent;
	}
}

.form--dark {

	.dx-texteditor-input,
	.dx-texteditor.dx-editor-outlined {

		&:hover,
		&:focus {
			background: $color-neutral-light;
		}
	}

	.dx-selectbox,
	.dx-tagbox {

		.dx-texteditor-input,
		.dx-placeholder {
			color: $color-neutral-white;
		}

		.dx-texteditor-input,
		&.dx-tagbox {
			background: $color-neutral-base;
			@include single-transition;

			&:hover,
			&:focus {
				background: $color-neutral-dark;
			}
		}

		.dx-show-clear-button .dx-icon-clear {
			color: $color-neutral-lighter;
		}
	}
}


/** dx-dropdowneditor */

.dx-dropdowneditor-button {
	padding: 0;
}

.dx-dropdowneditor-icon {
	border: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	background-color: $color-secondary-base;
	color: white;
	width: 34px;
	@include single-transition;
}

.dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon,
.dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon {
	background-color: $color-secondary-dark;
}

.dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon,
.dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon {
	background-color: $color-secondary-dark;
	color: white;
}

/** dx-radiobutton */

.radio-group--striped {
	.dx-radiobutton {
		padding: $spacing-tiny;

		&:nth-child(even) {
			background: $color-neutral-lightest;
		}
	}
}

.dx-selection {
	.dx-radiobutton-icon {
		position: relative;
	}

	.dx-radiobutton-icon-dot {
		display: block;
		width: 10px;
		height: 10px;
		background: $color-secondary-base;
		content: "";
		border-radius: 5px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}


/** dx-slider */

.dx-slider-handle {
	width: $spacing-small;
	height: $spacing-small;
	margin-top: $spacing-small * -0.5;
	background-color: $color-secondary-base;
	border-radius: 50%;
	border-color: $color-secondary-base;
	z-index: 10;

	&.dx-state-focused:not(.dx-state-active),
	&.dx-state-hover:not(.dx-state-active),
	&.dx-state-active {
		background-color: $color-secondary-dark;
		border-color: $color-secondary-dark;
	}
}

.dx-slider-bar {
	background: $color-neutral-light;
	height: $border-width-thicker;

	&::before,
	&::after {
		content: '';
		width: $border-width-thicker;
		height: $spacing-small;
		background: $color-neutral-light;
		position: absolute;
		top: -(($spacing-small - $border-width-thicker) * 0.5); // Centers handle on the bar
	}

	&::before {
		left: 0;
	}

	&::after {
		right: 0;
	}
}

.dx-slider-range.dx-slider-range-visible {
	background: $color-neutral-base;
	border-color: $color-neutral-base;
}

.dx-selectbox .dx-texteditor-input {
	border-radius: 4px 0 0 4px;
}
