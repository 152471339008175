/* ==========================================================================
   SAO DESIGN SYSTEM STYLES - UTILITIES
   ========================================================================== */

/**
 * Utilities come last so they can most effectively override previously defined styles.
 */

/**
 * CONTENTS
 *
 * UTILITIES
 * Clearfix.............Bind our clearfix onto a utility class.
 * Headings.............Reassigning our heading styles to helper classes.
 * Spacings.............Nudge bits of the DOM around with these spacing
 *                      classes.
 * Print................Reset-like styles taken from the HTML5 Boilerplate.
 * Hide.................Helper classes to hide content
 */





// UTILITIES
@import "utilities/utilities.clearfix";
@import "utilities/utilities.colors";
@import "utilities/utilities.headings";
@import "utilities/utilities.spacings";
@import "utilities/utilities.print";
@import "utilities/utilities.hide";
@import "utilities/utilities.typography";
