.c-input {

    border: $border-width-base solid $color-neutral-light;
    width: 100%;
    display: block;
    border-radius: $border-radius-base;
    padding: $spacing-small;

    &:focus {
        border-color: $color-secondary-base;
        background: $color-neutral-lightest;
        outline: none;
    }
}