.c-radio-button {

    &__label {
        display: inline-block;
        vertical-align: middle;
        line-height: 18px;
        position: relative;

        &::before {
            content: " ";
            width: 18px;
            height: 18px;
            border-radius: 50%;
            border: $border-width-base solid $color-neutral-base;
            background-color: $color-neutral-white;
            float: left;
            margin-right: $spacing-tiny;
        }
    }
    
    &__input {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
    }
    
    &__input:checked + &__label::after {
        content: " ";
        width: 10px;
        height: 10px;
        background-color: $color-secondary-base;
        border-radius: 50%;
        position: absolute;
        top: 4px;
        left: 4px;
    }
}
