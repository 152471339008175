@import "schedule-browser";

/**
    PeerSearch
 */
.peer-search {
	background: $fit-gray-bg;
	padding: 1em;

	&__status {
		display: flex;
		flex-direction: row;
		margin-bottom: 3em;

		button {
			padding: 0.75em;
		}
	}

	&__tag-cloud-wrapper, &__selection-wrapper {
		background: $white;
	}

	&__tag-cloud-wrapper {
		@include border-radius;
		padding: 1em;
		width: 100%;

		.dx-widget.dx-texteditor {
			border: none;
			text-align: center;

			.dx-clear-button-area {
				text-align: left;
			}

			.dx-placeholder {
				width: 100%;
				margin: 0 auto;
				text-align: center;
			}
		}
	}

	&__note {
		text-align: left;
		position: relative;
		top: -1em;
		left: 2em;
	}

	&__submit {
		margin-left: 1em;
		width: 10em;
		height: 6em;
	}

	&__filter {
		@include clearfix;
		width: 100%;
		padding: 0.5em;

		> label {
			font-style: italic;
			font-weight: bold;
		}

		label, .dx-widget {
			display: inline-block;
			vertical-align: middle;
		}

		&__left {
			float: left;
			width: auto;
		}

		&__right {
			float: right;
			width: auto;

			div { display: inline-block; }
		}
	}
}

/**
    DisplayOptions
*/
.display-options {

	&__popup {
		&__option, &__option > * {
			display: inline-block;
			vertical-align: middle;
		}

		&__option > * {
			margin: 0 0.25em;
		}

		&__options-group {
			text-align: center;
			margin-top: 1em;

			& > * {
				margin-left: 1em;
			}
		}

		&__submit {
			@extend .choice;
			display: block;
			padding-left: 4em;
			padding-right: 4em;
			margin: 2em auto 0 !important;
		}
	}
}

.metric-year {
	text-align: right;

	label, div {
		display: inline-block;
		vertical-align: middle;
	}
}

// single-select
.government-search {
	.government-chooser {
		margin-bottom: 1em;
	}

	.choice {
		margin: 0 1em 1em 1em;
	}
}

.government-chooser {

	&__row-template {
		.name, .expand {
			display: inline-block;
			vertical-align: middle;
		}
		.name {
			width: 75%;
			overflow: hidden;
		}
		.expand {
			width: 25% !important;
		}

		& + .dx-master-detail-row {
			.dx-master-detail-cell {
				padding: 0 30px 0 0 !important;
			}
		}
	}

	.disabled {
		color: $fit-gray-lt;
		cursor: not-allowed;

		.dx-select-checkbox {
			display: none !important;
		}

		&.dx-state-hover td {
			color: $fit-gray-lt !important;
			background: transparent !important;
		}
	}

	.selector {
		text-align: center;
	}

	&__detail-template {
		white-space: normal;
	}
}

.government-types {
	&__popup .checkbox-grid__list {
		@include columns(3);
	}

	&__popup .dx-popup-normal {
		overflow: auto;
	}

	&__submit {
		@extend .choice;
		margin: 1em auto 0;
	}
}

.filter-launcher {
	&__button {
		color: $fit-gray-lt;

		&, &--active {
			.fa { font-size: 1.5em; }
		}
	}
}

.columnar-accordion {
	@include clearfix;

	&__row, &__content {
		@include clearfix;
		width: 100%;
	}

	&__row {
		text-align: left;
	}

	&__item {
		text-align: center;
		width: 49%;
		display: inline-block;
		cursor: pointer;
		padding: 0.5%;
		background: $bonanza-blue-medium;
		color: $white;
		margin: 1%;
		&:nth-child(1) { margin-left: 0; }
		&:nth-child(2) { margin-right: 0; }
		@include single-transition;

		&:hover {
			background: $bonanza-blue-dark;
		}

		&--active {
			cursor: default;
			background: $white;
			color: $text;
			margin-bottom: 0;
			padding-bottom: 1.5%;

			&:hover {
				background: $white;
			}
		}
	}
}

.multi-government-chooser-novice {

	&__filter-strip {
		width: 100%;
		text-align: left;
		background: $white;

		&__sort {
			div, span {
				display: inline-block;
				vertical-align: middle;
			}
		}
	}

	.add-all {
		padding: 0.25em 0.5em !important;
	}

	.disabled {
		color: $fit-gray-lt;

		.dx-command-select {
			cursor: not-allowed;
		}

		&.dx-state-hover td {
			color: $fit-gray-lt !important;
			background: transparent !important;
		}
	}

}

.map-chooser {
	text-align: center;
	// button that launches the map
	&__launcher {
		padding: 0;
	}

	&__container {
		@include clearfix;
	}

	&__locations {
		float: left;
		width: 40%;
		text-align: left;

		.dx-field-label, &__value {
			float: none;
			width: 80%;
		}
	}

	&__map {
		float: left;
		width: 60%;

		&__tooltip {
			background-color: $bonanza-green-medium;
			@include border-radius;
			opacity: 0.7;
			color: $white;
			font-size: 1.5em;
			padding: 0.5em;
		}
	}

	&__notes {
		margin: -3em 0 3em 0;
	}

	&__submit {
		width: 10em;
		margin: 0 auto !important;
	}
}

.choose-one {
	> * {
		vertical-align: middle;
		display: inline-block;
		margin-bottom: 2em;
	}

	&__item {
		&__button {
			width: 9em;
			height: 5em;
			background: $fit-gray-bg;
			cursor: pointer;

			&--active {
				border: solid 0.125em $blue-md;
			}

			&--disabled {
				color: $fit-gray-lt;
				cursor: not-allowed;
			}
		}

		&__hint {
			margin: 0 1em 0 0.25em;
		}
	}
}

.lgfrs-throwback-panel {
	width: 100%;
	position: relative;
	text-align: center;
	font-weight: bold;
	background: $fit-gray-lt;
	color: $white;

	&__close {
		position: absolute;
		left: 0;
	}
}

.options-drawer {
	position: relative;

	&__handle {
		@include white-on-light-blue;
		width: 10em;
		padding: 0.25em 1em 0.25em 2.75em;
		cursor: pointer;
		position: absolute;
		right: 1em;
		top: -1.750em;
		@include single-transition;

		&:hover {
			color: $bonanza-blue-dark;
			text-shadow: none;
		}

		.options-drawer__handle__hide, .options-drawer__handle__show {
			display: none;
		}

		i {
			@include single-transition(transform);
			position: absolute;
			top: 0.5em;
			left: 1em;
			text-shadow: none;
		}
	}

	&--open {
		.options-drawer__handle i {
			@include rotate(45deg);
		}
		.options-drawer__handle__hide { display: inline-block; }
	}

	&--closed {
		.options-drawer__handle__show { display: inline-block; }
	}

	&__content, &__summary {
		background-color: $bonanza-blue-light;
		padding: 0.5em;
		@include single-transition;
	}

	&--closed {
		& .options-drawer__handle--closed {
			display: inline;
		}

		& .options-drawer__content {
			max-height: 0;
			padding: 0;
			visibility: hidden;
		}

		& .options-drawer__summary {
			max-height: 4em;
		}
	}

	&--open {
		& .options-drawer__handle--open {
			display: inline;
		}

		& .options-drawer__content {
			max-height: 10em;
		}

		& .options-drawer__summary {
			max-height: 0;
			visibility: hidden;
			padding: 0 0.5em;
		}
	}

	.dx-checkbox-text {
		@include white-on-light-blue;
	}

}

.government-information {
	&__launcher {
		border: none !important;

		&:hover {
			background: transparent;
		}
	}

	&__content {
		float: left;
		width: 50%;
	}

	&__map {
		float: left;
		width: 50%;
		margin-top: -5em;
		margin-bottom: -7em;
	}

	&__description {
		@include clearfix;
		clear: both;

		h4 {
			font-style: italic;
			font-weight: bold;
		}
	}
}

.account-chooser, .account-chooser-freeform {
	display: inline-block;

	&__launcher {
		margin-right: 0.5em;
		&__icon {
			color: $bonanza-blue-dark;
			fill: currentColor; // set svg fill to same as color
			padding: 0 0.5em;
			font-size: 1.25em;
		}
	}

	&__popup {
		position: absolute;
	}

	&__tree-view {
		margin: 0 6.25em 0;
	}

	&__account-item {
		&__code {
			vertical-align: baseline;
		}
	}

	&__submit {
		@extend .choice;
		margin: 1em auto 0;
		display: block;
	}
}

.account-chooser-freeform {
	.choice {
		margin: 5em 0 0 0;
	}
}

.fund-chooser {
	display: inline-block;

	&__launcher {
		margin-right: 0.5em;
		&__icon {
			color: $bonanza-blue-dark;
			fill: currentColor; // set svg fill to same as color
			padding: 0 0.5em;
			font-size: 1.25em;
		}
	}

	&__popup {
		position: absolute;
	}

	&__submit {
		@extend .choice;
		display: block;
		margin: 1em auto 0;
	}
}

.expenditure-object-chooser {
	display: inline-block;

	&__launcher {
		margin-right: 0.5em;
	}

	&__popup {
		position: absolute;

		.checkbox-grid {
			&__list {
				margin: 0 6.2em;
			}

			&__submit {
				@extend .choice;
				display: block;
				margin: 1em auto 0;
			}
		}
	}
}

.tab-reuse-dialog {
	&__popup {
		&__content {
			text-align: center;
			padding-bottom: 1em;

			&__update-existing {
				margin-left: 1em;
			}
		}
	}
}

.disclaimer {
	z-index: 5000 !important;

	&__actions {
		@include clearfix;
		margin-top: 2em;

		&__agree {
			text-align: center;
		}
	}
}

.checkbox-grid {
	&__list {
		margin: 0 1em;
		padding: 0;
		list-style: none;

		li {
			display: flex;
			margin: 0.5em 0;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			.dx-checkbox {
				margin-right: 1em;
			}
		}
	}

	&__submit {
		@extend .choice;
		margin: 1em auto 0;
		display: block;
		text-align: center;
	}
}

.launcher {
	display: inline-block;
	margin-right: 0.5em;

	&__button {
		&__icon {
			color: $bonanza-blue-dark;
			fill: currentColor; // set svg fill to same as color
			margin: 0 0.5em !important;
			font-size: 1.25em;
		}
	}

	&__popup {
		position: absolute;
	}

	&__content {
		min-width: 50em;
		margin: 0 6.25em;
	}
}

.report-chooser {
	flex: 0 1 auto;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;

	.dx-selectbox {
		width: 20em;
	}
}

.year-chooser {
	flex: 0 1 auto;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;

	&__mode, &__start, &__end {
		margin-left: 1em;
		display: flex;
		align-items: center;
	}

	&__label {
		margin-right: 1em;
	}

	&__mode {
		.dx-selectbox { width: 12em; }
	}

	&__start, &__end {
		.dx-selectbox { width: 8em; }
	}

	&__start {
	}

	&__end {
	}
}

.hint {
	display: inline-block;

	&__target {
		cursor: help;

		&__text {
			font-style: italic;
			color: $bonanza-blue-medium;
		}

		&__icon {
			@include single-transition;
			&:hover { color: $bonanza-blue-medium; }
		}
	}

	&__popover {
		.dx-overlay-content .dx-popup-content > .dx-template-wrapper {
			padding: 20px;
		}
	}
}

.filer-report {
	&__sub-title {
		text-align: center;
	}

	&__notes {
		font-size: 0.85em;
		list-style-type: none;
	}

	&__data-grid {
		.dx-header-filter {
			color: $bonanza-blue-medium;
			@include single-transition;

			&:hover {
				color: $bonanza-blue-dark;
			}
		}

		.dx-header-filter-empty {
			color: $bonanza-blue-light;
		}

		.dx-datagrid-export-button {
			@include export-button;
		}

		.dx-pager {
			.dx-pages {
				.dx-page {
					@include border-radius(0);
					background-color: $bonanza-green-medium;
					color: $white;
					@include single-transition;
					border: solid 2px transparent;

					&:hover {
						background-color: $bonanza-green-dark;
					}
				}

				.dx-selection, .dx-page-sizes .dx-selection {
					border: solid 2px $bonanza-green-dark;
				}
			}
		}
	}
}
