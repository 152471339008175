.o-form {

    &__field {
        margin-bottom: $spacing-base;
    }

    &__label {
        text-transform: uppercase;
        @include font-size($font-size-small);
        color: $color-neutral-base;
        margin-bottom: $spacing-tiny;
    }

    &__input {}

    &--inline {

        & .o-form__field {
            margin-bottom: 0;
            display: grid;
            grid-template-columns: 200px 1fr;
            grid-column-gap: $spacing-base;
        }

        & .o-form__label {
            display: block;
            width: 200px;
            float: left;
            text-align: right;
        }

        & .o-form__input {
            display: block;
            width: calc(100% - 200px - #{$spacing-base});
            float: left;
            padding-bottom: $spacing-base;
            padding-left: $spacing-base;
            margin-left: $spacing-base;
            border-left: $border-width-base solid $color-neutral-light;
        }
    }
}

@supports (display: grid) {

    .o-form {

        &--inline {

            & .o-form__input {
                width: 100%;
                margin-left: 0;
            }
        }
    }
}
