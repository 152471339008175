/* ==========================================================================
   #TYPOGRAPHY
   ========================================================================== */

/**
 * Miscellaneous typography utilities
 *
 */

.u-text-align-center {
    text-align: center !important;
}

.u-text-align-right {
    text-align: right !important;
}

.u-text-align-left {
    text-align: left !important;
}

.u-text-transform-none {
    text-transform: none !important;
}

.u-text-transform-uppercase {
    text-transform: uppercase !important;
}
