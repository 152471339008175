/**
 * White text on light blue background, with text shadow for legibility.
 */
@mixin white-on-light-blue() {
	background-color: $bonanza-blue-light;
	color: white;
	@include text-shadow(1px 1px 2px lighten($bonanza-blue-medium, 15%));
}

/**
 * FontAwesome for ::before and ::after blocks. Provide `content` for icon.
 */
@mixin font-awesome-solid() {
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	font-family: "Font Awesome 5 Free";
	font-weight: 900;
}

// overrides dx styles for dxPivotGrid and dxDataGrid
@mixin export-button() {
	border: $border-width-base solid $color-secondary-base;
	color: $color-neutral-white;
	background: $color-secondary-base;
	text-transform: uppercase;
	font-weight: $font-weight-bold;
	border-radius: $border-radius-base;
	padding: $spacing-tiny $spacing-small;
	font-size: $font-size-small;
	margin-bottom: $spacing-tiny;
	@include single-transition;

	&:hover,
	&:focus {
		border-color: $color-secondary-dark;
		background: $color-secondary-dark;
	}

	&::before {
		content: "Download"
	}

	&::after {
		@include font-awesome-solid;
		content: "\f019";
		margin-left: 0.5em;
	}

	.dx-button-content {
		display: none;
	}
}

@mixin flex-parent() {
	display: flex;
	flex-direction: column;
	height: 100%;
}
