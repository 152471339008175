$label-width-small: 150px;
$label-width-base: 200px;
$label-width-large: 250px;

.dx-field {
    margin: 0;
    display: flex;

    &-label {
        border-right: $border-width-base solid $color-neutral-light;
        padding: $spacing-small $spacing-base $spacing-small 0;
        width: $label-width-base;
        text-align: right;
        overflow: visible;
        white-space: normal;
        font-weight: $font-weight-medium;

        .field-label--small & {
            width: $label-width-small;
        }

        .field-label--large & {
            width: $label-width-large;
        }
    }


    &-value,
    &-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button) {
        padding: 0 0 0 $spacing-base;
        width: calc(100% - #{$label-width-base});
        margin-bottom: $spacing-base;
        display: flex;

        .field-label--small & {
            width: calc(100% - #{$label-width-small});
        }

        .field-label--large & {
            width: calc(100% - #{$label-width-large});
        }

        & > *:first-child {
            width: 100%;
        }
    
        & > .dx-switch:first-child {
            width: 44px;
            margin-right: $spacing-large;
        }
    }

    &-value:not(.dx-widget) > .dx-switch {
        float: none;
    }
}
