.c-button {
    text-transform: uppercase;
    color: $color-neutral-white;
    padding: $spacing-small $spacing-base;
    border: $border-width-base solid $color-neutral-dark;
    border-radius: $border-radius-base;
    background-color: $color-neutral-dark;
    font-weight: $font-weight-bold;
    cursor: pointer;

    &:hover, &:focus {
        border-color: $color-neutral-darker;
        background-color: $color-neutral-darker;
    }
}

.c-button--primary {
    background-color: $color-secondary-base;
    border-color: $color-secondary-base;

    &:hover, &:focus {
        border-color: $color-secondary-dark;
        background: $color-secondary-dark;
    }
}

.c-button--no-op {
    border-radius: 0;
    border: 0;
    background-color: $color-secondary-light;
    text-transform: none;

    &:hover, &:focus {
        background-color: $color-secondary-base;
    }
}

.c-button[disabled],
.c-button--disabled {
    color: $color-neutral-darkest;
    background-color: $color-neutral-base;
    border-color: $color-neutral-base;
    cursor: default;

    &:hover, &:focus {
        color: $color-neutral-darkest;
        background-color: $color-neutral-base;
    }
}
