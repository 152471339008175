@import 'settings/settings.tokens';

.sidebar__buttons {
	height: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
	list-style: none;
	padding: 0;
	margin: 0;
}

.sidebar__button {
	padding: $spacing-base 0;
	margin: 0 $spacing-base;
	border-bottom: $border-width-base solid $color-neutral-lighter;
	color: $color-body-text;
	font-size: $font-size-small;
	flex: 0 1 auto;

	&:hover,
	&:focus {
		color: $color-neutral-dark;
	}

	&:last-child {
		border-bottom: 0;
	}

	&--active {
		color: $color-accent-teal;

		&:hover,
		&:focus {
			color: $color-accent-teal;
		}
	}
}

.sidebar__icon {
	display: block;
	margin-bottom: $spacing-small;
	font-size: $font-size-h3;
}
