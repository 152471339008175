/* ==========================================================================
   #TYPOGRAPHY
   ========================================================================== */

// Borrowed from https://github.com/inuitcss/inuitcss

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */





// HEADINGS

// We have all of our heading font sizes defined here. Passing these pixel
// values into our `font-size()` mixin will generate a rem-based
// `font-size` with a pixel fallback, as well as generating a `line-height` that
// will sit on our baseline grid.

h1 {
  @include font-size($font-size-h1);
  font-family: $font-family-condensed;
  font-weight: $font-weight-light;
  color: $color-neutral-base;
}

h2 {
  @include font-size($font-size-h2);
  font-family: $font-family-condensed;
  font-weight: $font-weight-light;
  color: $color-neutral-base;
}

h3 {
  @include font-size($font-size-h3);
  font-family: $font-family-condensed;
  font-weight: $font-weight-bold;
  color: $color-neutral-base;
}

h4 {
  @include font-size($font-size-h4);
  font-family: $font-family-condensed;
  font-weight: $font-weight-bold;
  color: $color-neutral-base;
}

h5 {
  @include font-size($font-size-h5);
  font-family: $font-family-condensed;
  font-weight: $font-weight-bold;
  color: $color-neutral-base;
}

h6 {
  @include font-size($font-size-h6);
  font-family: $font-family-condensed;
  font-weight: $font-weight-bold;
  color: $color-neutral-base;
}





// PARAGRAPH

p {
  color: $color-neutral-darkest;
}





// LINKS

a {
  color: $color-secondary-base;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}





// INLINE ELEMENTS

strong {
  font-weight: $font-weight-bold;
}

em {
  font-style: italic;
}
