@import 'settings/settings.tokens';
@import './field';
@import '~compass-mixins-fixed';

/** dx-button */

.dx-button {
	border: $border-width-base solid $color-neutral-dark;
	color: $color-neutral-white;
	background: $color-neutral-dark;
	text-transform: uppercase;
	font-weight: $font-weight-bold;
	border-radius: $border-radius-base;
	@include single-transition;

	&:hover,
	&:focus {
		border-color: $color-neutral-darker;
		background-color: $color-neutral-darker;
	}

	.dx-button-content {
		padding: $spacing-small $spacing-base;
		font-size: $font-size-small;
	}

	.dx-button-default {
		border-color: $color-secondary-base;
		background: $color-secondary-base;

		&:hover,
		&:focus {
			border-color: $color-secondary-dark;
			background: $color-secondary-dark;
		}
	}

	.dx-icon,
	fa-icon {
		color: $color-neutral-white;
		fill: currentColor;
		margin-left: 10px;
	}

	&.button--nonoperative {
		text-transform: none;
		background: $color-secondary-light;
		border-color: $color-secondary-light;
		border-radius: 0;
		@include single-transition;

		.dx-icon {
			color: $color-neutral-white;
			fill: currentColor;
			margin-right: $spacing-tiny;
		}

		&:hover,
		&:focus,
		&.dx-state-hover.dx-state-hover {
			background: $color-secondary-base;
			border-color: $color-secondary-base;
		}
	}
}

.dx-list-static-delete-button {
	.dx-icon {
		margin-left: -16px;
	}
}



.field-buttons {
	display: flex;

	&__left {
		width: $label-width-base;
		margin-right: $spacing-base;
		padding-right: $spacing-base;
		text-align: right;

		.field-label--small & {
            width: $label-width-small;
        }

        .field-label--large & {
            width: $label-width-large;
        }
	}

	&__right {
		flex: 1 1 auto;
		text-align: left;
	}
}
