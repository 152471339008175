.wrapper {
	height: 100%;
	display: flex;
	flex-direction: row;
	flex: 1;

	&__main {
		width: calc(100% - 200px);
		display: flex;
		flex: 1;
		flex-direction: column;
		position: relative;
		padding-right: 24px;
	}
}

// TODO: move to design system
.u-max-height-100 {
	height: 100%;
	max-height: 100%;
}
