.table {
	th, td {
		padding: 25px 12px;
	}

	tr {
		> *:first-child {
			padding-left: 25px;
		}

		> *:last-child {
			padding-right: 25px;
		}
	}

	&--zebra {
		tr:nth-child(even) {
			th, td {
				background: $color-neutral-lightest;
			}
		}
	}
}
