/*
 * DO NOT EDIT: this file is generated from sao-patterns/src/tokens/tokens.js
 * Add or modify the variables in that file then re-run the design system build.
 */
$color-primary-light: #5076DB;
$color-primary-base: #3957AA;
$color-primary-dark: #001E61;
$color-secondary-light: #55AEE2;
$color-secondary-base: #026CA3;
$color-secondary-dark: #014164;
$color-accent-blue: #B9D6E3;
$color-accent-orange: #FDDB8D;
$color-accent-green: #959A14;
$color-accent-teal: #4C9F83;
$color-accent-indigo: #49346B;
$color-accent-indigo-light: #a597b3;
$color-neutral-white: #FFFFFF;
$color-neutral-lightest: #F4F6F2;
$color-neutral-lighter: #DAD9D7;
$color-neutral-light: #BCBCBC;
$color-neutral-base: #979693;
$color-neutral-dark: #81807E;
$color-neutral-darker: #646464;
$color-neutral-body: #575753;
$color-neutral-darkest: #444444;
$color-status-okay: #8DB153;
$color-status-current: #8DB153;
$color-status-cautionary: #F6B333;
$color-status-historical: #F6B333;
$color-status-concerning: #E8702D;
$color-status-projection: #E8702D;
$color-status-changed: #F3E234;
$color-body-text: #575753;
$font-family-regular: 'Roboto', sans-serif;
$font-family-condensed: 'Roboto Condensed', sans-serif;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-tiny: 12px;
$font-size-h1: 56px;
$font-size-h2: 44px;
$font-size-h3: 28px;
$font-size-h4: 22px;
$font-size-h5: 20px;
$font-size-h6: 16px;
$border-radius-base: 4px;
$border-radius-small: 2px;
$border-width-base: 1px;
$border-width-thick: 2px;
$border-width-thicker: 4px;
$border-width-thickest: 6px;
$spacing-baseline: 6px;
$spacing-tiny: 6px;
$spacing-small: 12px;
$spacing-base: 24px;
$spacing-large: 48px;
$spacing-huge: 96px;
