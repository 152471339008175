/* ==========================================================================
   #COLORS
   ========================================================================== */

/**
 * Utility classes to apply design system colors onto an element.
 * Generates classes for each defined color value like so:
 *
 *      * .u-color-primary-base
 *      * .u-background-color-primary-base
 *      * .u-border-color-primary-base
 */

$color-properties: (
    "color": "color",
    "background-color": "background-color",
    "border-color": "border-color"
) !default;

$color-values: (
    "primary-light": $color-primary-light,
    "primary-base": $color-primary-base,
    "primary-dark": $color-primary-dark,
    "secondary-light": $color-secondary-light,
    "secondary-base": $color-secondary-base,
    "secondary-dark": $color-secondary-dark,
    "accent-blue": $color-accent-blue,
    "accent-orange": $color-accent-orange,
    "accent-green": $color-accent-green,
    "accent-teal": $color-accent-teal,
    "accent-indigo": $color-accent-indigo,
    "neutral-white": $color-neutral-white,
    "neutral-lightest": $color-neutral-lightest,
    "neutral-lighter": $color-neutral-lighter,
    "neutral-light": $color-neutral-light,
    "neutral-base": $color-neutral-base,
    "neutral-dark": $color-neutral-dark,
    "neutral-darker": $color-neutral-darker,
    "neutral-darkest": $color-neutral-darkest,

    "status-okay": $color-status-okay,
    "status-current": $color-status-current,

    "status-cautionary": $color-status-cautionary,
    "status-historical": $color-status-historical,

    "status-concerning": $color-status-concerning,
    "status-projection": $color-status-projection,

    "status-changed": $color-status-changed
) !default;

@each $property-namespace, $property in $color-properties {

    @each $color-namespace, $color in $color-values {

        .u-#{$property-namespace}-#{$color-namespace} {
            #{$property}: $color !important;
        }
    }
}
