@import 'settings/settings.tokens';

/** dx-popup */

.dx-popup-title {
	color: $color-neutral-darker;
}

.dx-popup-content {
	//overflow: auto; // Use dx-scroll-view if you need scrolling
	padding: 30px;
}

/** mapboxgl-popup */

.mapboxgl-popup-content {
	border-radius: 4px;
	padding-top: 5px;

	#govProfile {
		display: flex;
		flex-direction: column;
	}

	h4 {
		text-align: center;
		padding-right: 20px;
		margin-top: -3px;
	}

	.mapboxgl-popup-close-button {
		background-color: #026ca3;
		color: white;
	}
	.mapboxgl-popup-close-button:hover {
		background-color: #014b71 ;
	}
}

/**
 * DX Popup styling overwrites
 */
.dx-popup-wrapper > .dx-overlay-content {
	border-radius: 0;
	border-color: $color-neutral-light;
	box-shadow: none;

	&.dx-state-focused {
		border-color: $color-neutral-light;
	}
}

.dx-popup-title.dx-toolbar {
	background: $color-neutral-lighter;
	color: $color-neutral-darker;

	.dx-closebutton {
		@include single-transition();
		width: auto;
		height: auto;
		opacity: 0.5;

		&:hover {
			opacity: 1;
			background: transparent !important;
			border-color: transparent !important;
		}

		.dx-icon {
			color: $color-neutral-darker;
		}
	}
}

// TODO: May be a better place for this line... it affects all popups right now
.dx-popup-content {
	padding: 0;
}

.app-popup {
	&__wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	&__inner {
		padding: 30px;
		overflow: auto;
		flex: 1 1 auto;
	}

	&__footer {
		width: 100%;
		background: $color-neutral-lighter;
		color: $color-neutral-darker;
		padding: 12px;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex: 0 1 auto;
	}

	&__pre-footer {
		width: 100%;
		flex: 0 1 auto;
		padding: 0 30px;

		h1, h2, h3, h4, h5 {
			margin: 0 0 0.5em !important;
		}
	}

}

.dx-button-content {
	line-height: 1.3;
}
