@import 'settings/settings.tokens';

/** Overrides to DevExtreme styles */

/** Broad font-family overrides */
.dx-widget,
.dx-field,
.dx-overlay-wrapper {
	font-family: $font-family-regular;
}

// override dx default light gray for row/column headers with body color
// including arrows
.dx-pivotgrid .dx-pivotgrid-area td,
.dx-pivotgrid .dx-pivotgrid-collapsed .dx-expand,
.dx-pivotgrid .dx-pivotgrid-expanded .dx-expand {
	color: $color-body-text;
}

// Use design system colors for alternating datagrid rows
.dx-datagrid .dx-row-alt > td, .dx-datagrid .dx-row-alt > tr > td {
	background-color: $color-neutral-lightest;
}

.dx-pivotgrid .dx-pivotgrid-area-data tbody td.indicator {
	&.good { background-color: lighten( $color-status-okay, 20%); }
	&.cautionary { background-color: lighten($color-status-cautionary, 20%); }
	&.concerning { background-color: lighten($color-status-concerning, 20%); }
	&.indeterminate { background-color: lighten($color-neutral-base, 20%); }
}

// Schools/OSPI reports
.dx-pivotgrid tbody td.ospi-bold {
	font-weight: bold;
}



