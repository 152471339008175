@import 'settings/settings.tokens';

.grouping-editor {
	&__popup {
		[appScrollableShadow] {
			height: calc(100% - 66px);
		}

		position: absolute;

		.dndDragging {
			@include border-radius();

			// remove adornments
			span,
			div {
				display: none;
			}
		}

		.dx-popup-content {
			padding: 0;
		}
	}

	&__scroll-view {
		padding: 24px 90px;
	}

	&__pane-container {
		@include clearfix();
		width: 100%;
		max-width: 560px;
		margin: 24px 0 0 0;
	}

	&__recommended {
		&__header {
			color: $color-neutral-white;
			background: $color-secondary-base;
			font-size: 1.25em;
			padding: 24px;

			.hint {
				margin-left: 0.75em;

				&__target {
					&__text {
						color: $color-neutral-light;
					}

					font-size: 0.85em;
					font-weight: normal;
					font-style: italic;
				}
			}
		}

		&__content {
			background: darken($color-secondary-base, 7%);
			//column-count: 2;
			padding: 24px;

			> .dx-button {
				display: inline-block;
				margin-right: 24px;
			}

			&__header {
				color: $color-neutral-white;
				border-bottom: 5px solid $color-neutral-light;
				margin-bottom: 1em;
				text-transform: uppercase;
				font-weight: 500;

				.fa {
					margin-right: 0.5em;
				}
			}
		}

		&__message {
			text-align: center;
			font-weight: bold;
			color: lighten($color-secondary-base, 2%);
			text-transform: uppercase;
		}
	}


	&__available {
		position: relative;
		background: $color-secondary-base;
		padding: 4px;

		&--drop-target {
			background-color: lighten($color-secondary-base, 7%);
		}

		&__header {
			padding: 0.5em;
			font-size: 1.25em;
			color: $color-neutral-white;
		}
	}

	&__lgfrs-formats {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			&:hover {
				cursor: pointer;
				color: $blue-md;
			}
		}
	}

	&__rows,
	&__columns {
		background: $color-neutral-lighter;
		position: relative;
		margin-top: 2em;
		border: solid 4px $color-neutral-lighter;

		&__header {
			text-transform: uppercase;

			fa-icon {
				margin-right: 8px;
			}
		}

		&--drop-target {
			border-color: $color-secondary-light;
		}
	}

	&__columns {
		&__header i {
			@include rotate(90deg);
		}
	}

	&__available,
	&__rows,
	&__columns {
		@include single-transition();

		&__header {
			padding: 20px 24px 24px;
		}

		// do not display item that is being moved
		.dndDraggingSource {
			display: none;
		}

		&--not-allowed {
			&::after {
				font-size: 1.5em;
				cursor: not-allowed;
				position: absolute;
				background: $color-neutral-base;
				content: 'Sorry, this field can\'t be dropped here for this report.';
				color: $color-neutral-white;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				z-index: 15;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	&__available__list,
	&__columns__list,
	&__rows__list {
		padding: 0 20px;
		margin: 0;
		list-style: none;
		min-height: 68px;
	}

	&__available__list {
		margin: 0;
		min-height: 67px;

		.grouping-editor__field {
			&:hover {
				background-color: $color-secondary-light;
			}
		}
	}

	&__field {
		position: relative;
		padding: 12px;
		margin: 0 24px 24px 0;
		background: $color-neutral-light;
		display: inline-block;
		cursor: -webkit-grab;
		cursor: grab;
		z-index: 11;
		@include border-radius();

		&:hover {
			background: $color-neutral-base;
		}

		.hint {
			padding: 0 0 0 5px;
		}

		&__suffix {
			&--ordered {
				font-family: 'Font Awesome 5 Free';
				position: absolute;
				display: block;
				right: -1.15em;
				top: 7px;
				width: 1em;
				color: lighten($color-neutral-lighter, 8%);
				font-size: 1.5em;
				line-height: 1.5em;
				z-index: 10;
			}

			&--linked {
				position: absolute;
				border: 0.25em solid lighten($color-neutral-lighter, 8%);
				width: 2em;
				right: -2em;
				top: 17px;
				z-index: 10;
			}
		}

		&--disabled {
			cursor: not-allowed;
			background: lighten($color-neutral-lightest, 5%);
			border: 1px dashed lighten($color-neutral-base, 20%);

			&:hover {
				background: lighten($color-neutral-lightest, 5%);
			}
		}
	}

	&__available__list {
		&__field {
			background: $color-neutral-white;
			@include single-transition();
		}
	}

	&__recommended_list,
	&__available__list,
	&__columns__list,
	&__rows__list {
		.dndPlaceholder {
			background: $color-secondary-base;
			height: auto;
			margin: 0 24px 24px 0;
			display: inline-block;
			padding: 12px;
			color: $color-neutral-white;
			position: relative;
			z-index: 11;
			@include border-radius();
		}
	}

	&__description {
		max-width: 40em;
		margin: 0 auto;
		font-style: italic;
	}

	// Replaced with dxpopup / _popup.scss styling
	//&__footer {
	//	position: relative;
	//	top: 2em;
	//	width: 100%;
	//	max-width: 40em;
	//	margin: 0 auto 2em;
	//	text-align: center;
	//}

	&__remove {
		background: $color-neutral-dark;
		color: $color-neutral-white;
		width: $spacing-base;
		height: $spacing-base;
		line-height: $spacing-base;
		border-radius: 50%;
		position: absolute;
		top: -($spacing-base * 0.5);
		left: -($spacing-base * 0.5);
		text-align: center;
		vertical-align: middle;

		&:hover,
		&:focus {
			background: $color-neutral-darker;
		}
	}
}
