.large-ctas {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	list-style: none;
	padding: 0;
	margin: 0 !important;

	li {
		width: calc(50% - 0.5rem);
		display: block;
		margin-bottom: 1rem;
	}

	img {
		width: 100%;
		max-width: 100%;
		height: auto;
		margin: 0;
		min-height: 1px; /* IE fix */
	}

	button {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;

		span {
			@include single-transition();
			width: 100%;
			background: $color-secondary-base;
			padding: 0 30px;
			text-align: center;
			color: $color-neutral-white;
			font-family: $font-family-condensed;
			font-size: 1.4rem;
			font-weight: 100;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 68px;
		}

		&:hover span {
			background: $color-secondary-light;
		}
	}
}
