/* ==========================================================================
   #CLEARFIX
   ========================================================================== */

// Borrowed from https://github.com/inuitcss/inuitcss

/**
 * Attach our clearfix mixin to a utility class.
 */

.u-clearfix {
  @include clearfix();
}
