dx-validation-summary {
	.dx-validationsummary-item {
		color: $color-neutral-white;
		background-color: $color-status-projection;
		font-weight: bold;
	}

	.dx-validationsummary-item-content {
		border-bottom: none;
	}
}
