@import "./mixins";

html {
	height: 100%;
	overflow: hidden;
}

body {
	@include establish-baseline;
	//color: $color-neutral-darkest;
	color: $color-body-text;
	background-color: $color-neutral-white;
	margin: 0;
	padding: 0;
	height: 100%;
}

[class^="icon-"] {
	width: 18px;
	height: 18px;
}

.nobr {
	white-space: nowrap;
}

#phone-message, #portrait-message {
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	background: $color-neutral-dark;
	color: $color-neutral-white;
	text-align: center;
	h1 { color: $color-neutral-white; }
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

@media only screen and (orientation: portrait) {
	#portrait-message {
		display: flex;
	}
}

@media only screen and (max-device-width: 767px) {
	#phone-message {
		display: flex;
	}
}

.error {
	color: $negative;
	position: relative;

	.fa {
		font-size: 3em;
		position: absolute;
		left: -0.25em;
	}
}

em {
	font-weight: bold;
}

a {
	@include single-transition;
}

a.button, button, input[type="button"] {
	color: $color-secondary-base;
	text-decoration: none;
	margin: 0;
	padding: $spacing-small;
	border: 0;
	background: transparent;
	outline: none;
	font-size: inherit;
	@include single-transition;

	&:hover {
		color: $color-secondary-dark;
		cursor: pointer;
	}

	&.choice {
		font-style: normal;
		box-sizing: border-box; // draw 'active' border inside
		background: $color-secondary-base;
		color: white;
		@include single-transition;
		padding: $spacing-small $spacing-base;
		border: solid 2px transparent;

		&.arrow {
			font-size: 1.25em;
			position: relative;
			padding-left: 2em;
			//padding-right: 3em;
			padding-right: 2em;

			//&::after {
			//    display: inline-block;
			//    font-style: normal;
			//    font-variant: normal;
			//    text-rendering: auto;
			//    -webkit-font-smoothing: antialiased;
			//    font-family: "Font Awesome 5 Free";
			//    font-weight: 900;
			//    content: "\f04b";
			//
			//    font-size: 0.75em;
			//    position: absolute;
			//    right: 2em;
			//    top: 1.375em;
			//    color: $bonanza-green-dark;
			//}

			&:hover {
				&::after {
					color: $color-secondary-dark;
				}
			}
		}

		&:hover {
			background: $color-secondary-dark;
		}

		&.active {
			border: solid 2px $color-secondary-dark;
		}
	}

	&.disabled {
		color: $color-neutral-darker;
		background: $color-neutral-light;
		cursor: not-allowed;

		&:hover {
			color: $color-neutral-darker;
			background: $color-neutral-light;
		}
	}
}

nav {
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		@include clearfix;

		li {
			float: left;
			padding-right: 1em;
		}
	}
}

/* https://tympanus.net/codrops/2014/01/21/dot-navigation-styles/ */
.dot-nav {
	position: relative;
	display: inline-block;
	margin: 0;
	padding: 0;
	list-style: none;
	cursor: default;

	li {
		position: relative;
		display: block;
		float: left;
		margin: 0 1em;
		width: 1em;
		height: 1em;
		cursor: pointer;

		&::before, &::after {
			cursor: default;
			border: 0.125em solid $color-neutral-darker;
			top: 0.375em;
			width: 1.25em;
			content: '';
			position: absolute;
			float: left;
			z-index: -100;
		}

		&::before { left: -0.938em; }
		&::after { left: 0.938em; }
		&:first-child::before { display: none; }
		&:last-child::after { display: none; }

		&.indeterminate {
			&::before {
				//height: 0.5em;
				position: relative;
				top: 0.375em;
				border-color: $color-neutral-darker;
				width: 4em;
				border-width: 0.125em 4em;
				height: 0.125em;
				@include border-image(linear-gradient(to right, $color-neutral-darker, $color-neutral-white, $color-neutral-white) 1 100%);
			}
		}

		button {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			outline: none;
			border-radius: 50%;
			background-color: $color-neutral-darker;
			box-shadow: inset 0 0 0 0.125em $color-neutral-darker;
			text-indent: -999em;
			cursor: pointer;
			position: absolute;
			@include single-transition;

			&:hover {
				background-color: $color-neutral-darker;
			}

			&.selected {
				background-color: $color-neutral-white;
				cursor: default;
				&:hover { background-color: $color-neutral-white; }
			}

			&.disabled {
				background-color: $color-neutral-base;
				cursor: not-allowed;
			}
		}
	}
}

.binary-selection {
	@include clearfix;
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;

	&__caption {
		color: $color-secondary-base;
	}
}

//.multiple-selection {
//	padding: 1em;
//	border-collapse: separate;
//
//	th {
//		background: $color-neutral-darker;
//		color: white;
//		font-weight: 300;
//		font-size: 1.125em;
//		padding: 0.75em 1em;
//		border-bottom: 1em solid white;
//	}
//
//	td, th {
//		width: 32%;
//	}
//
//	td {
//		vertical-align: top;
//		position: relative;
//	}
//
//	th:nth-child(2), th:nth-child(4), td:nth-child(2), td:nth-child(4) {
//		background: transparent;
//		width: 2%;
//	}
//
//	button {
//		height: 8.25em;
//		line-height: 1.125;
//		padding: 0.75em;
//	}
//
//	.hint {
//		position: absolute;
//		z-index: 100;
//		right: 0;
//		bottom: -1.5em;
//	}
//}

.description {
	white-space: normal;
}

.smcx-embed {
	margin: 0 auto !important;
}

.switch-label {
	margin: 0 1em;
	&--on {
		font-weight: bold;
	}
}
