/* ==========================================================================
   #PAGE
   ========================================================================== */

// Borrowed from https://github.com/inuitcss/inuitcss

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in ems, the `line-height` is calculated to exist unitlessly.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

 @use "sass:math";

html {
  font-family: $font-family-regular;
  font-size: math.div($font-size-base, 16px) * 1em; /* [1] */
  line-height: math.div($spacing-base, $font-size-base);
  min-height: 100%; /* [3] */
}
