
app-wizard {
	padding: 0;
}
.wizard {
	height: 100%;
	padding: 0;
	display: flex;
	flex-direction: column;

	form {
		height: 100%;
		@include flexbox((
			display: flex,
			flex-direction: column
		));
	}

	.switch-track {
		display: inline-block;
		margin: 0 auto;
	}

	&__snapshot-selector {
		text-align: right;
		color: $fit-gray-lt;
	}

	h1, h2, h3, h4, h5, h6 {
		color: $bonanza-blue-medium;
	}

	font-size: 1em;
	text-align: center;
	.dx-widget { text-align: left; }

	.nav {

		.binary-selection {
			height: 2em;
			margin-bottom: 2em;
			position: relative;
			.right, .left {
				position: absolute;
				text-transform: uppercase;
			}
			.left {
				left: 0;
			}
			.right {
				right: 0;
			}
		}

		.steps {
			@include clearfix;
			@extend .dot-nav;

			& + .caption {
				text-transform: uppercase;
				color: $bonanza-blue-dark;
			}
		}
	}

	.panes {
		width: 100%;
		height: auto;
		margin: 0 auto;
		@include flexbox((
			display: flex,
			flex-direction: column,
			flex: 1
		));

		&__include {
			@include flexbox((
				display: flex,
				flex-direction: column
			));
			height: 100%;
		}

		.content {
			width: 75%;
			//max-width: 45em;
			margin: 0 auto;
		}

		.binary-selection {
			margin: 4em 0 2em 0;
		}
	}

	.less {
		font-size: 0.75em;
	}

	&__d3 {
		@include flexbox((
			display: flex,
			flex-direction: column
		));
		height: 100%;

		&__account-chooser-freeform {
			height: 100%;
			overflow: hidden;
		}

		&__choices {
			margin-top: 2em;
		}

		.choice {
			margin: 0 1em 1em 1em;
		}
	}
}
