@import 'settings/settings.tokens';

/** dx-datagrid */

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
	padding: 7px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-expand {
	position: relative;
	cursor: pointer;

	&:before {
		@include font-awesome-solid;
		content: "\f067";
		background: $color-secondary-base;
		color: white;
		@include single-transition;
		margin-right: 1em;
		font-size: 10px;
		padding: 4px 5px;
		position: relative;
		top: -1px;
		line-height: 10px;
	}

	&:hover::before {
		background: $color-secondary-dark;
	}
}

html body {
	//.pivot-grid.dx-pivotgrid .dx-area-column-cell tr td.alternate:not(.measure):not(.first) {
	//	background-color: #746785;
	//}

	.pivot-grid.dx-pivotgrid .first {
		background-color: $color-neutral-darker;
	}
}
