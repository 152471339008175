/* Colors */
@use "sass:math";

$blue-dk: #001e61;
$blue-md: #3b8ede;
$blue-lt: #acc9e8;
$gray: #666;
$gray-dk: #333;
$disabled: #ccc;
$text: #666;
$positive: #0f9e5e;
$negative: #ff7080;
$danger: #f00;
$white: #fff;

$fit-orange-dk: #f28b00;
$fit-orange-lt: #f5c65d;
$fit-green-dk: #44883e;
$fit-green-med: #94d600;
$fit-green-lt: #c3e86c;
$fit-gray-lt: #bcbcbc;
$fit-gray-bg: #efefef;
$fit-off-gray-bg: #e5e7ec;
$fit-gray-dk: #8b8b8b;
$fit-purple: #8348ad;

//$bonanza-blue-dark: #3e6078;
//$bonanza-blue-medium: #68869c;
//$bonanza-blue-light: #a9bcca;
//$bonanza-green-dark: #678d3a;
//$bonanza-green-medium: #9bb96d;

$bonanza-background: #20283d;
$bonanza-blue-dark: #646e7f;
$bonanza-blue-medium: #505769;
$bonanza-blue-light: #888c98;
$bonanza-green-dark: #266abd;
$bonanza-green-medium: #266abd;

/* Mixin defaults */
$base-font-size: 16px;
$base-line-height: 24px;

/* Transitions */
$default-transition-property: all;
$default-transition-duration: 0.2s;
$default-transition-function: false;
$default-transition-delay: false;
$default-transition-function: ease-in-out;
$default-transition: $default-transition-property $default-transition-duration $default-transition-function;

/* Box Shadow */
$default-box-shadow-color: rgba(0, 0, 0, 0.175);
$default-box-shadow-h-offset: 0;
$default-box-shadow-v-offset: 6px;
$default-box-shadow-blur: 12px;

/* Border */
$default-border-radius: 4px;

/* Menu */
$large-menu: math.div(960px, $base-font-size) * 1em;

/* Breakpoints */
$screen-large-down: max-width 1920px;
$screen-large-only: (min-width: 1601px) (max-width 1920px);
$screen-large-up: min-width 1601px;
$screen-medium-down: max-width 1600px;
$screen-medium-only: (min-width: 1261px) (max-width 1600px);
$screen-medium-up: min-width 1261px;
$screen-small-down: max-width 1260px;
$screen-small-only: (min-width: 1081px) (max-width 1260px);
$screen-medium-up: min-width 1081px;
$screen-tiny-down: max-width 1080px;
