.c-checkbox {

    &__label {
        display: inline-block;
        vertical-align: middle;
        line-height: $spacing-base;

        &::before {
            content: " ";
            width: $spacing-base;
            height: $spacing-base;
            border: $border-width-base solid $color-neutral-base;
            background-color: $color-neutral-white;
            border-radius: $border-radius-small;
            color: $color-secondary-base;
            margin-right: $spacing-tiny;
            text-align: center;
            float: left;
        }
    }
    
    &__input {
        opacity: 0;
        width: 0;
        height: 0;
        position: absolute;
    }
    
    &__input:checked + &__label::before {
        border-color: $color-secondary-base;
        content: "\f00c";
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
    }

}
