@import '~breakpoint-sass';
@import '../../node_modules/compass-mixins-fixed/lib/compass';
@import 'system-core';
//@import url('https://fonts.googleapis.com/css?family=Oswald:300');
@import url('https://fonts.googleapis.com/css?family=PT+Sans');
@import 'variables';
@import 'layout';

@media screen {
	@import 'devextreme';
	@import 'common';
	@import 'common-components';
	@import 'directives';

	// TODO move all definitions into their respective components and get rid of legacyStyles
	@import 'legacyStyles/ui';
	@import 'legacyStyles/criteria';
	@import 'legacyStyles/wizard';
	@import 'legacyStyles/landing';
	@import 'legacyStyles/components';
}

@media print {
	@import 'print';
}

// Apparently there is a bug in angular that prevents this @page from being picked up from components, so it must go here. See https://github.com/angular/angular/issues/26269
@page {
	margin-top: 0.32in;
	margin-bottom: 0.32in;
	margin-left: 0.50in;
	margin-right: 0.50in;
}

@import 'modules/table';

@import 'system-utilities';
