.tooltip-icon {
    color: $color-secondary-base;
    font-size: $font-size-h4;
    margin-left: $spacing-small;
    align-self: center;

    &:hover,
    &:focus {
        color: $color-secondary-dark;
    }
}
