[appScrollableShadow] {
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;

	.appScrollableShadow-overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		transition: box-shadow 0.4s;
		transform: translate3d(0, 0, 0);
		pointer-events: none;
		z-index: 10;

		&.more-above {
			box-shadow: inset 0 10px 5px -8px rgba(0, 0, 0, 0.2),
			inset 0 -10px 5px -8px transparent;
		}

		&.more-below {
			box-shadow: inset 0 10px 5px -8px transparent,
			inset 0 -10px 5px -8px rgba(0, 0, 0, 0.2);
		}

		&.more-below.more-above {
			box-shadow: inset 0 10px 5px -8px rgba(0, 0, 0, 0.2),
			inset 0 -10px 5px -8px rgba(0, 0, 0, 0.2);
		}
	}
}
