.stripe {
	width: 100%;
	background: $fit-gray-lt;
	color: white;
	font-weight: 500;
	padding: 1em 0;
	margin-bottom: 2em;

	> * {
		display: inline-block;
		vertical-align: middle;
	}

	.component {
		float: left;
		width: auto;
	}
}

.submit {
	margin-top: 1em !important; // .dx-popup-content .dx-button
	text-align: center !important; // .dx-popup-content .dx-button
}
