@import '~scss/variables';
@import 'settings/settings.tokens';

.peer-set {
	margin-bottom: 1rem;

	&__title {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		padding: 0 16px;

		h4 {
			margin: 0 1rem 0 0;
			line-height: 1;
		}

		a {
			margin-right: 1rem;
		}
	}

	&__description {
		padding: 0 16px;
		margin: 0.4rem 0 0;
		color: $color-neutral-darker;
	}

	&__items {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		align-content: flex-start;
		flex-wrap: wrap;
	}

	&__item {
		width: 100px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 16px 32px 16px 0;
		text-align: center;
		font-weight: bold;
		font-size: 0.8em;
		position: relative;
		flex: 0 1 auto;

		wasao-badge {
			position: absolute;
			top: -0.667em;
			right: 0;
			font-size: $font-size-small;
		}

		span {
			// force ie to wrap text
			width: 100%;
		}

		&--placeholder {
			opacity: 0.25;
		}

		&--drop-target {
			color: $color-secondary-light;
			opacity: 1;
			fa-icon {
				border-color: $color-secondary-light;
			}
		}
	}

	&__icon {
		position: relative;
		&__caption {
			position: absolute;
			bottom: 1em;
			left: 0;
			width: 100%;
		}
	}

	&__icon--building {
		width: 100%;
		font-size: 48px;
		display: block;
		padding: 12px 0;
		margin-bottom: 8px;
		border: solid 1px $fit-gray-dk;
		border-radius: 8px;
	}

	&__icon--delete {
		position: absolute;
		z-index: 100;
		top: -8px;
		left: -5px;
		background: $color-neutral-darkest;
		color: $color-neutral-white;
		border-radius: 9999px;
		width: 22px;
		height: 22px;
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.25s ease-out;
		cursor: pointer;

		&:hover {
			background: $color-neutral-darker;
		}
	}

	&--small-icons {
		.peer-set__item {
			width: 79px;
			margin: 0 24px 0 0;
		}

		.peer-set__icon--building {
			font-size: 32px;
			padding: 14px;
		}
	}
}
