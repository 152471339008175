@import 'settings/settings.tokens';

/** dx-tabpanel */

.dx-tabpanel {
	overflow: hidden;

	.dx-tabpanel-tabs {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
		overflow-x: auto;
		overflow-y: hidden;

		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
			background-color: rgba(0, 0, 0, 0.1);
		}

		&::-webkit-scrollbar {
			height: 8px;
			background-color: transparent;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: $border-radius-base;
			box-shadow: inset 0 0 6px rgba(0, 0, 0, .2);
			-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .2);
			background-color: rgba(0, 0, 0, 0.1);
		}
	}

	.dx-tabs {
		display: flex !important;
		border: none; // remove top border
		background: transparent;
	}

	.dx-tabs-wrapper {
		background: $color-neutral-lighter;
		border-top-left-radius: $border-radius-base;
		border-top-right-radius: $border-radius-base;
	}

	.dx-tabpanel-container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	&:not(.tab-panel--fixed) {
		.dx-tabpanel-container {
			margin-top: 0 !important;
		}
	}

	.dx-multiview-wrapper {
		height: 100%;
		border: none;
		overflow: auto;
	}

	//.dx-multiview-item-container {
	//	height: auto;
	//}

	.dx-tab {
		width: auto;
		border-top-right-radius: $border-radius-base;
		border-top-left-radius: $border-radius-base;
		border: $border-width-base solid $color-neutral-lighter;
		border-bottom: none;
		background: $color-neutral-lighter;
		color: $color-neutral-darker;
		padding: $spacing-tiny $spacing-large $spacing-tiny $spacing-small;

		&.dx-tab:not(.dx-tab-selected) {
			background: $color-neutral-lighter;

			&:hover,
			&:focus {
				background: $color-neutral-light;
			}
		}

		&.dx-tab-selected {
			background: $color-neutral-white;
			//color: $color-secondary-base;
			cursor: auto;

			.tab-panel__title__delete {
				cursor: pointer;
			}

			&:before,
			&:after {
				border-top-right-radius: $border-radius-base;
				border-top-left-radius: $border-radius-base;
			}
		}

		.tab-panel__title__delete {
			position: absolute;
			right: $spacing-small;
			color: $color-neutral-base;

			&:hover,
			&:focus {
				color: $color-neutral-darker;
			}
		}
	}

	&.tab-panel--fixed {

		.dx-tabpanel-tabs,
		.dx-tabpanel-container {
			position: relative;
			top: 0;
		}

		.dx-multiview-item-container {
			height: 100%;
		}
	}
}
