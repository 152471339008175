.landing {
	text-align: center;
	width: 90%;
	max-width: 52em;
	margin: 0 auto;

	.inner {
		width: 80%;
		margin: 0 auto;
	}

	&__header, &__subheader {
	}

	&__header {
		margin-top: 0.5em;
		margin-bottom: 0;
		font-size: 2em;
		color: $bonanza-blue-medium;
	}

	&__subheader {
		color: $bonanza-green-medium;
		font-size: 1.25em;
		//margin: 0.5em 0;
	}

	p {
		@extend .inner;
		font-style: italic;
	}

	&__stripe {
		margin: 2em 0 0 0;
		padding: 1.5em 1em;
		background: $bonanza-blue-medium;
		color: white;
		line-height: 1.5;
	}

	&__get-started.button {
		display: inline-block;
		margin-bottom: 2em;
	}

	&__questions {
		padding: 0;
		margin: 0 auto 2em;
		list-style: none;
		width: 100%;

		.tag {
			height: 5em;
			padding: 1em;
			position: relative;
			width: 11em;
			color: white;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.tag, .icon {
			margin-top: 2em;
		}

		.icon {
			height: 5.5em;
			width: 5.5em;

			img {
				width: 100%;
				height: 100%;
				opacity: 1;
			}
		}

		li {
			vertical-align: middle;
			font-size: 0.875em;
			display: inline-block;
			padding: 1em 1.25em;

			&:nth-child(1) {
				.icon {
					margin-left: 9em;
					margin-bottom: 1em;
				}
			}

			.top {
				@include clearfix;

				.icon {
					margin-left: 0.75em;
				}

				.tag, .icon {
					float: left;
				}

				.tag {
					margin-right: 1.5em;
					width: 11.5em;
				}
			}

			.bottom {
				@include clearfix;

				.icon {
					margin-right: 1em;
				}

				.tag, .icon {
					float: right;
				}

				.tag {
					margin-left: 1.5em;
				}
			}

			&:nth-child(3) {
				.tag {
					margin-left: 1em;
				}
			}
		}

		li:nth-child(1) .tag {
			background: $bonanza-blue-medium;
		}

		li:nth-child(2) {
			.top .tag {
				background: $bonanza-blue-dark;
			}

			.bottom .tag {
				background: $bonanza-blue-light;
			}
		}

		li:nth-child(3) .tag {
			background: $bonanza-blue-medium;
		}
	}
}

/**
    Arrows http://www.cssarrowplease.com/
 */
.arrow-up:after {
	bottom: 100%;
	left: 11.5em;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba($bonanza-blue-medium, 0);
	border-bottom-color: $bonanza-blue-medium;
	border-width: 20px;
	margin-left: -20px;
}

.arrow-right:after {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba($bonanza-blue-dark, 0);
	border-left-color: $bonanza-blue-dark;
	border-width: 20px;
	margin-top: -20px;
}

.arrow-down:after {
	top: 100%;
	left: 1.5em;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba($bonanza-blue-medium, 0);
	border-top-color: $bonanza-blue-medium;
	border-width: 20px;
	margin-left: -20px;
}

.arrow-left:after {
	right: 100%;
	top: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba($bonanza-blue-light, 0);
	border-right-color: $bonanza-blue-light;
	border-width: 20px;
	margin-top: -20px;
}

