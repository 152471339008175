.schedule-browser {
	@include flex-parent;

	h2 {
		margin-bottom: 0;
	}

	h3 {
		margin-top: 0;
	}

	h4 {
		text-transform: uppercase;
	}

	&__options-drawer-wrapper {
		content: '';
		clear: both;
		width: 100%;
		//padding-top: 1em;
	}

	&__title {
		display: block;
		width: 100%;
		position: relative;
		padding-left: 3em;
		color: $bonanza-blue-medium;
		@include clearfix;

		h2 {
			position: relative;

			button {
				position: absolute;
				left: -2em;
			}
		}

	}

	&__criteria {
		display: flex;
		justify-content: space-between;
		margin: 1em 3em 0 3em;
	}

	&__narrative {
		display: none;
		margin: 0 0 0 2em;
		height: 2.25em;
		position: relative;
		width: 90%;
		@include single-transition;

		&__content {
			display: inline-block;
		}

		&__more {
			font-style: italic;
			position: absolute;
			right: -5em;
			top: 0;
			display: inline-block;
			vertical-align: top;
			cursor: pointer;
			color: $bonanza-blue-medium;
			@include single-transition;

			&:hover {
				color: $bonanza-blue-dark;
			}
		}

		&--collapsed {
			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				height: 1em;
				width: 100%;
				@include background(linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1)));
			}
		}

		&--expanded {
			height: 100%;
			padding-bottom: 1em;
		}

		&--active {
			display: block;
		}
	}

	&__pivot-grid-wrapper {
		flex: 1;
		height: 100%;
		// this was causing the PG to be truncated before redraw (hides loading panel), is it needed?
		// position: relative;
	}

	&__pivot-grid {
		position: absolute;
		height: 100%;
		width: 100%;
	}
}

.pivot-grid__export-button.button.choice {
	padding: 0.25em 0.5em;
	margin: 1em;
	display: none; // do not display before moved into dxPivotGrid
}

.pivot-grid.dx-pivotgrid {
	border-top: 1px solid rgb(221, 221, 221);

	.pivot-grid__export-button.button.choice {
		display: inline-block;
	}

	// all areas: column, row, data
	.dx-pivotgrid-area {
		tr td {
			position: relative;

			.options-launcher {
				position: absolute;
				right: 0;
				top: 0.5em;
				opacity: 0;
				padding: 0.25em 0.5em 0.25em 2em;
				cursor: pointer;
				@include single-transition;
				// default colors for white cell, override for colored backgrounds below
				color: $bonanza-blue-light;
				@include background(linear-gradient(to right, transparent, #fff, #fff));

				&:hover {
					color: $bonanza-blue-medium;
				}
			}

			&:hover {
				.options-launcher {
					opacity: 1;
				}
			}

		}
	}

	// column area
	.dx-area-column-cell tr td {
		// first row with gradient
		&.first {
			.options-launcher {
				background: transparent;
				color: mix($bonanza-blue-medium, white);

				&:hover {
					color: white;
				}
			}

			.dx-expand {
				color: white;
			}
		}

		// odd rows
		&.alternate:not(.measure):not(.first) {
			//@include white-on-light-blue;
			.dx-expand {
				color: white;
			}

			.options-launcher {
				text-shadow: none;
				color: mix($bonanza-blue-light, white);
				@include background(linear-gradient(to right, transparent, $bonanza-blue-light, $bonanza-blue-light));

				&:hover {
					opacity: 1;
					color: white;
				}
			}
		}

		// Amounts, Comparison, etc
		&.measure {
			color: $bonanza-blue-medium;

			.dx-expand {
				color: white;
			}
		}
	}

	// row, data area
	.dx-area-row-cell, .dx-area-data-cell {
		tr td.dx-row-total .options-launcher {
			@include background(linear-gradient(to right, transparent, #f8f8f8, #f8f8f8));
		}
	}

	.dx-pivotgrid-export-button {
		@include export-button;
		margin: 1em !important;
	}

}
