/*
You can, and should, build custom headings for components as needed instead of relying on the generic styling.
Here is an example of custom typography treatment.
*/

.c-typography-example {
    margin-bottom: $spacing-base;
    margin-left: $spacing-large;
    padding-left: $spacing-base;
    border-left: $border-width-thick solid $color-neutral-base;

    &__heading {
        @include font-size($font-size-h4);
        color: $color-accent-teal;
        text-transform: uppercase;
    }
    
    &__text {
        color: $color-neutral-base;
        font-weight: $font-weight-medium;
    }

}
